<template>
  <v-dialog
    transition="scale-transition"
    :max-width="600"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-overlay
        class="v-overlay-fill-size"
        :value="loading"
        absolute
      >
        <o-loader />
      </v-overlay>

      <o-form
        ref="form"
        @submit="submit"
        @close="$emit('input', false)"
      >
        <v-card-title class="primary--text d-flex justify-space-between">
          <span>{{ $t('messages.views.admin.components.configuration.users.userFormModal.title.' + mode) }}</span>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <o-text-field
                v-model="form.email"
                :rules="validation.email"
                label="labels.user.email"
                prepend-icon="mdi-rename-box"
                dense
                autofocus
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-select
                v-model="form.roles"
                :items="roles.map(item => ({
                  text: $t('constants.user.role.' + item),
                  value: item,
                }))"
                :placeholder="$t('labels.user.roles')"
                prepend-icon="mdi-label"
                multiple
                clearable
              >
                <template v-slot:selection="props">
                  <o-chip-user-role
                    :role="props.item.value"
                    small
                  />
                </template>
              </o-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-submit />
            </v-col>
          </v-row>
        </v-card-text>
      </o-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { getRoles } from '@/utils/user';
  import { checkRouteAccess } from '@/utils/security';
  import router from '@/plugins/router';

  export default {
    props: {
      userId: {
        type: [String, Number],
        default: null,
      },
    },

    apollo: {
      user: {
        query: require('@gql/views/admin/components/configuration/users/userFormModal/getUser.gql'),
        client: 'api-gateway',
        variables: function () {
          return {
            id: this.userId,
          };
        },
        fetchPolicy: 'no-cache',
        skip () {
          return this.userId === null;
        },
        result (res, key) {
          this.loading = res.loading;
        },
        update: function (data) {
          this.form = data.getUser;

          return data.getUser;
        },
      },
    },

    data: function () {
      return {
        loading: false,
        form: {
          email: '',
          roles: [],
        },
        validation: require('@/validation/entities/user.json'),
        roles: getRoles(),
      };
    },

    computed: {
      mode () {
        return this.userId ? 'update' : 'create';
      },
    },

    created () {
      if (this.userId) {
        this.loading = true;
      }
    },

    methods: {
      submit (scope) {
        this.loading = true;
        const mutation = !this.userId
          ? require('@gql/mutations/user/createUser.gql')
          : require('@gql/mutations/user/updateUser.gql')
        ;

        this.$apollo.mutate({
          mutation: mutation,
          client: 'api-gateway',
          fetchPolicy: 'no-cache',
          variables: {
            input: this.form,
          },
        }).then((response) => {
          if (this.userId === this.$store.state.auth.user.id) {
            // Reload user
            this.$store.dispatch('auth/loadUserFromToken', { force: true }).then(() => {
              const redirect = checkRouteAccess(router.currentRoute);
              if (redirect !== null) {
                router.push(redirect);
              }
            });
          }
        }).then((response) => {
          this.$emit('saved', this.form);
          this.$emit('input', false);
        }).catch((e) => {
          this.$flash(null, 'error');
        }).finally(() => {
          this.loading = false;
        });
      },

      close () {
        this.$refs.form.close();
      },
    },
  };
</script>
